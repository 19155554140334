import { FhirDataTypesMod } from "models/fhirDataTypes";
import { FhirMeasureReportMod } from "models/measureReport";
import { FhirPatientMod } from "models/patient";
import { FhirTaskMod } from "models/task";
import { MeasureReportGroups } from "../pages/orders/RequestReport";
import { urlBackNestApp } from "routes/urls";
import { getDefaultHeaders } from "../api/agent";

interface SplitReference {
  resourceType: string;
  uuid: string;
}

/**
 * Performs a validation if there is a string matching the pattern ResourceName/uuid in the last part of the string then returns the matching string
 * @param value a string that could be and url that points to a resource or a resource reference
 */
export function getResourceRef(value: string) {
  const regexFhirResourceRef = /([A-z]+)(\/){1}([\w\-]+)$/g;

  if (regexFhirResourceRef.test(value)) {
    const matchedValues = value.match(regexFhirResourceRef);

    if (matchedValues?.[0] !== undefined) {
      return matchedValues[0];
    }
  }

  return undefined;
}

export function getSplitReference(value: string): SplitReference | null {
  if(!value) return null;
  const splitedValue = value.split("/");
  const splitRe: SplitReference = {
    resourceType: splitedValue[0],
    uuid: splitedValue[1],
  };

  return splitRe;
}

export function getTaskNumber(task: FhirTaskMod.Task) {
  const taskNumber = task.identifier?.find(
    (value: FhirDataTypesMod.Identifier) =>
      value.type?.text === "NJ InCK system task number"
  )?.value;

  return taskNumber === undefined ? "" : taskNumber;
}

function getSystemValueFromIdentifiers(
  list: FhirDataTypesMod.Identifier[],
  system: string | undefined
) {
  if (system === undefined) return undefined;
  if (!list) return "No exist";
  return list.find((value: FhirDataTypesMod.Identifier) => {
    return value.system === system;
  })?.value;
}

export function getMRNFromIdentifiers(list: FhirDataTypesMod.Identifier[]) {
  const system = process.env.REACT_APP_SYSTEM_MRN;
  return getSystemValueFromIdentifiers(list, system);
}

export function getSocialSecurityNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SOCIAL_SECURITY_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getMedicaIdNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_MEDICA_ID;
  return getSystemValueFromIdentifiers(list, system);
}

export function getBirthCertificateNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_BIRTH_CERTIFICATE_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getDriverLicenseNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_DRIVER_LICENSE_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getFamilyIdentifierFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_FAMILY_IDENTIFIER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getServiceRequestNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_SERVICE_REQUEST_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getTaskNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_TASK_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getKeycloakIDFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_KEYCLOAK_ID;
  return getSystemValueFromIdentifiers(list, system);
}

export function getNPIFromIdentifiers(list: FhirDataTypesMod.Identifier[]) {
  const system = process.env.REACT_APP_SYSTEM_NPI;
  return getSystemValueFromIdentifiers(list, system);
}

function getSystemValueFromContactPoints(
  list: FhirDataTypesMod.ContactPoint[],
  system: string | undefined
) {
  if (system === undefined) return undefined;

  return list.find((item: FhirDataTypesMod.ContactPoint) => {
    return item.system === system;
  })?.value;
}

export function getPhoneFromTelecom(list: FhirDataTypesMod.ContactPoint[]) {
  const system = "phone";
  return getSystemValueFromContactPoints(list, system);
}

export function getEmailFromTelecom(list: FhirDataTypesMod.ContactPoint[]) {
  const system = "email";
  return getSystemValueFromContactPoints(list, system);
}

function filterContactPointsWhereSystem(
  list: FhirDataTypesMod.ContactPoint[],
  system: string | undefined
) {
  if (system === undefined || list === undefined) return undefined;

  let filteredList: FhirDataTypesMod.ContactPoint[] = [];

  list.forEach((item: FhirDataTypesMod.ContactPoint) => {
    if (item.system !== undefined && item.system === system)
      filteredList.push(item);
  });

  return filteredList;
}

export function getPhonesFromTelecom(list: FhirDataTypesMod.ContactPoint[]) {
  const system = "phone";
  return filterContactPointsWhereSystem(list, system);
}

export function getEmailsFromTelecom(list: FhirDataTypesMod.ContactPoint[]) {
  const system = "email";
  return filterContactPointsWhereSystem(list, system);
}

export function getRefFromInOutPut(
  item: FhirTaskMod.Output | FhirTaskMod.Input
) {
  if (item.valueReference?.reference !== undefined) {
    return getSplitReference(item.valueReference.reference);
  } else if (item.valueUri !== undefined) {
    return getSplitReference(item.valueUri);
  }

  return undefined;
}

function getValueTypeFromPatientLink(
  patient: FhirPatientMod.Patient,
  matchingSystem: string
) {
  if (patient.link === undefined) return undefined;

  for (let i = 0; i < patient.link?.length; i++) {
    const item = patient.link[i];

    if (item?.other?.type !== undefined && item?.other?.type === matchingSystem) {
      return item?.other?.display;
    }
  }

  return undefined;
}

export function getValueTypeFromPatientExtension(
  patient: FhirPatientMod.Patient,
  matchingSystem: string[],
  display: boolean = false
) {
  if (patient.extension === undefined) return undefined;
  for (let i = 0; i < patient.extension?.length; i++) {
    const item = patient.extension[i];
    const value = item?.valueCodeableConcept?.coding?.[0] 
      || item?.extension?.[0]?.valueCoding;

    const url = item?.url && matchingSystem?.find((url: string) => url === item?.url)

    if (url) {
      if (display && value?.display) {
        return value?.display;
      } else {
        return value?.code;
      }
    }
  }

  return undefined;
}

export function getRaceFromPatient(
  patient: FhirPatientMod.Patient,
  display: boolean = true
) {
  const system = [
    `ValueSet/${process.env.REACT_APP_RACE_VALUESET_ID}`, 
     process.env.REACT_APP_US_CORE_RACE || ""
  ];
  return getValueTypeFromPatientExtension(patient, system, display);
}

export function getEthnicityFromPatient(
  patient: FhirPatientMod.Patient,
  display: boolean = true
) {
  const system = [
    `ValueSet/${process.env.REACT_APP_ETHNICITY_VALUESET_ID}`,
    process.env.REACT_APP_US_CORE_ETHNICITY || ""
  ];
  return getValueTypeFromPatientExtension(patient, system, display);
}

export function getRaceFromPatient2(patient: FhirPatientMod.Patient) {
  const system = "CodeSystem/8c898f7c-f057-4d3d-809f-ee675e43bcd7";
  return getValueTypeFromPatientLink(patient, system);
}

export function getEthnicityFromPatient2(patient: FhirPatientMod.Patient) {
  const system = "CodeSystem/b131f368-6a6e-4de4-82a5-5768e24efeb4";
  return getValueTypeFromPatientLink(patient, system);
}

export function getUuidsFromInOutPut(
  items: FhirTaskMod.Output[] | FhirTaskMod.Input[],
  resourceType: string
): string[] {
  const uuids: string[] = [];

  items.forEach((item: FhirTaskMod.Output | FhirTaskMod.Input) => {
    const splitRef = getRefFromInOutPut(item);
    if (splitRef && splitRef.resourceType === resourceType) {
      uuids.push(splitRef.uuid);
    }
  });

  return uuids;
}

function isSystemInCoding(
  list: FhirDataTypesMod.Coding[] | undefined,
  matchingSystem: string | undefined
) {
  if (matchingSystem === undefined || list === undefined) return false;

  for (let i = 0; i < list.length; i++) {
    if (list[i].system === matchingSystem) return true;
  }

  return false;
}

export function isMedicalComplexityGroup(group: FhirMeasureReportMod.Group) {
  const matchingSystem = process.env.REACT_APP_SYSTEM_MEDICAL_COMPLEXITY;
  return isSystemInCoding(group.code?.coding, matchingSystem);
}

export function isSocialComplexityGroup(group: FhirMeasureReportMod.Group) {
  const matchingSystem = process.env.REACT_APP_SYSTEM_SOCIAL_COMPLEXITY;
  return isSystemInCoding(group.code?.coding, matchingSystem);
}

export function isServiceIntegrationLevelsGroup(
  group: FhirMeasureReportMod.Group
) {
  const matchingSystem =
    process.env.REACT_APP_SYSTEM_SERVICE_INTEGRATION_LEVELS;
  return isSystemInCoding(group.code?.coding, matchingSystem);
}

export const isMedicalObservationGroup = (group: any) => {
  const matchingSystem = 'https://healthcare.googleapis.com/v1/projects/zanenet-ctinck/locations/us-central1/datasets/dev-zanenet-ctinck/fhirStores/dev-zanenet-ctinck-datastore/fhir/CodeSystem?title=pmca';
//"https://healthcare.googleapis.com/v1/projects/zanenet-ctinck/locations/us-central1/datasets/dev-zanenet-ctinck/fhirStores/dev-zanenet-ctinck-datastore/fhir/CodeSystem?title=sil"
  return group?.resource?.code?.coding?.[0]?.system === matchingSystem;
}
export const isSocialObservationGroup = (group: any) => {
  const matchingSystem = 'https://healthcare.googleapis.com/v1/projects/zanenet-ctinck/locations/us-central1/datasets/dev-zanenet-ctinck/fhirStores/dev-zanenet-ctinck-datastore/fhir/CodeSystem/7b6cae74-7987-4f5e-89a2-3f47c932ab11';

  return group?.resource?.code?.coding?.[0]?.system === matchingSystem;
}
export const isServiceIntegrationLevelsObservationGroup = (group: any) => {
  const matchingSystem =
    "https://healthcare.googleapis.com/v1/projects/zanenet-ctinck/locations/us-central1/datasets/dev-zanenet-ctinck/fhirStores/dev-zanenet-ctinck-datastore/fhir/CodeSystem?title=sil";

  return group?.resource?.code?.coding?.[0]?.system === matchingSystem;
};

export function getMeasureReportGroups(
  measureReport: FhirMeasureReportMod.MeasureReport
) {
  let res: MeasureReportGroups = {
    medicalComplex: undefined,
    socialComplex: undefined,
    serviceIntegration: undefined,
    normal: [],
  };

  measureReport.group?.forEach((item: FhirMeasureReportMod.Group) => {
    if (isMedicalComplexityGroup(item)) {
      res.medicalComplex = undefined;
    } else if (isSocialComplexityGroup(item)) {
      res.socialComplex = undefined;
    } else 
    if (isServiceIntegrationLevelsGroup(item)) {
      res.serviceIntegration = item;
    } else {
      res.normal?.push(item);
    }
  });

  return res;
}

export function getObservationGroups(
  observations: any
) {
  let res: any = {
    medicalComplex: undefined,
    socialComplex: undefined,
    serviceIntegration: undefined,
    normal: [],
  };

  observations?.entry?.forEach((item: any) => {
    if (isMedicalObservationGroup(item)) {
      res.medicalComplex = undefined;
    } else if (isSocialObservationGroup(item)) {
      res.socialComplex = undefined;
    } else if (isServiceIntegrationLevelsObservationGroup(item)) {
      res.serviceIntegration = item;
    } else {
      res.normal?.push(item);
    }
  });

  return res;
}

export const getFhirCall = async (url: string) => {
  const fhirCall = await fetch(url, {
    method: "GET",
    headers: getDefaultHeaders(),
  });

  return await fhirCall.json();
};

export const getFhirOrganizationIds = (orgs: any) => {
  return orgs?.entry
  ? orgs?.entry
      .filter((i: any) => i?.resource?.resourceType === "Organization")
      .map((i: any) => i?.resource?.id)
  : [];
};

export const getUserOrgAndChilds = async (user: any) => {
  const { organization } = user;

  const fhirBaseUrl = `${urlBackNestApp}/fhirproxy/Organization?_id=`;
  const fhirUrl = `${fhirBaseUrl}${organization?.fhirUri?.slice(
    -36
  )}&_revinclude=Organization:partof`;

  const fhirResponse = await getFhirCall(fhirUrl);
  return getFhirOrganizationIds(fhirResponse);
};


export async function getFhirUserOrganizationsIds(orgs: string[], level = 3): Promise<string[]> {
  const fhirUrl = `${urlBackNestApp}/fhirproxy/Organization?_id=${orgs.join()}&_revinclude=Organization:partof`;

  let childOrgIds: any = [];

  while (level > 1 && orgs.length > 1) {
    const fhirResponse = await getFhirCall(fhirUrl);
    const getOrganizationsIds = getFhirOrganizationIds(fhirResponse);

    childOrgIds = childOrgIds.concat(getOrganizationsIds);
    childOrgIds = await getFhirUserOrganizationsIds(childOrgIds, --level);
  }

  return Array.from(new Set([...orgs, ...childOrgIds]));
}

export function setSILDisplay(sil: string) {
  let result: string = "";
  
  switch (sil) {
    case "1":
      result = "Service Integration Level 1";
      break;
    case "2":
      result = "Service Integration Level 2";
      break;
    case "3":
      result = "Service Integration Level 3";
      break;
    default:
      result = sil;
  }

  return result;
}


// export const getFhirUserOrganizationsIds = async (user: any) => {
//   const { organization } = user;

//     const fhirBaseUrl = `${urlBackNestApp}/fhirproxy/Organization?_id=`;
//     const fhirUrl = `${fhirBaseUrl}${organization?.fhirUri?.slice(-36)}&_revinclude=Organization:partof`;

//     let childOrgIds: any[] = [];

//     const fhirResponse = await getFhirCall(fhirUrl);
//     const getOrganizationsIds = getFhirOrganizationIds(fhirResponse);

//     for(const orgId of getOrganizationsIds) {
//       if(orgId === organization?.fhirUri?.slice(-36))
//         continue;

//       const childOrg = await getFhirCall(`${fhirBaseUrl}${orgId}&_revinclude=Organization:partof`);
//       const getOrgId = getFhirOrganizationIds(childOrg);

//       childOrgIds = childOrgIds.concat(getOrgId);
//     }

//     childOrgIds = childOrgIds.concat(getOrganizationsIds);

//     const fhirOrganizationsIds = childOrgIds.filter((val, index) => childOrgIds.indexOf(val) === index);

//     return fhirOrganizationsIds;
// }
